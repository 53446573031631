.leaflet-container a {
  color: inherit !important;
}

.leaflet-control-attribution.leaflet-control {
  display: none !important;
}

.leaflet-popup {
  max-width: 280px !important;
}

.leaflet-popup-content {
  max-width: 240px !important;
}

.leaflet-container a.leaflet-popup-close-button {
  top: 24px !important;
  right: 24px !important;
  width: 30px !important;
  height: 30px !important;
}

.leaflet-container a.leaflet-popup-close-button span {
  font-size: 30px !important;
  line-height: 30px !important;
  color: #7d003d !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 100 !important;
}
